//import Inputmask from "inputmask";
import 'materialize-css/dist/js/materialize'
import validate from 'jquery-validation'
import Cleave from 'cleave.js/dist/cleave-esm.js';

// This piece of code disables back-forward-cache on Apple devices
$(window).bind("pageshow", function(event) {
  if (event.originalEvent.persisted) {
    window.location.reload()
  }
});

$( document ).ready(function() {
  $('select').formSelect();
  jQuery.validator.addMethod('cardVerification', function(value, element) {
    return cc_mod10(element.value.replace(/\s*/g, ''))
  }); 
  $('select[required]').css({
    display: 'block', height: 0, padding: 0, width: 0,
  });

  // We have to manually clear the form entries since Microsoft Edge and Internet Explorer use save form entries option by default
  (function clearForm() {
    $('#address').val('Моя карта');
    var ids = ['#cardNumber', '#cardExpirationMonth', '#cardExpirationYear'];
    for (var i = 0; i < ids.length; i++) {
      $(ids[i]).val('');
    }
  })();

  var cleave = new Cleave('#cardNumber', {
    creditCard: true,
  });

  function showLoader() {
    $('#submitBtn').addClass('is-loading');
    $('.preloader').addClass('visible');
  }

  function cc_mod10(ccnum) {
    const len = ccnum.length;
    if (len < 16) return false;
    let i; let n; let 
      total = 0; 
    for (i = len - 1; i >= 0; --i) {
      total += Number(ccnum.charAt(i));
      if (--i < 0) break;
      n = 2 * Number(ccnum.charAt(i));
      if (n > 9) n = (n % 10) + Math.floor(n * 0.1);
      total += n;
    }
    return (total % 10) == 0;
  }

  /** *VALIDATION** */
  $.validator.setDefaults({
    //ignore: [],
    highlight: function(element, errorClass, validClass) {
      if (element.tagName === 'SELECT')
        $(element).closest('.select-wrapper').addClass('invalid');
      else
        $(element).removeClass(validClass).addClass(errorClass);
    },
    unhighlight: function(element, errorClass, validClass) {
      if (element.tagName === 'SELECT')
        $(element).closest('.select-wrapper').removeClass('invalid');
      else
        $(element).removeClass(errorClass).addClass(validClass);
    },
  });

  $.validator.addMethod('checkDate', function(inputDate, element) {

    if(!$('#cardExpirationYear').hasClass('untouched')){
      let month = $('[name=card_exp_month]').val().replace(/[^\d]/g, '');
      if (month.length != 2 || parseInt(month, 10) > 12 || parseInt(month) < 0 || parseInt(month) == '00') return false;

      let year = $('[name=card_exp_year]').val().replace(/[^\d]/g, '');
      if (year.length != 2 || parseInt(year, 10) < 0) return false;
      year = '20' + year;

      let selectedDate = new Date();
      selectedDate.setMonth(parseInt(month, 10)-1);
      selectedDate.setFullYear(parseInt(year, 10));
      selectedDate.setMinutes(0);
      selectedDate.setHours(0);
      selectedDate.setSeconds(0);
      selectedDate.setMilliseconds(0); 

      // console.log('selectedDate', selectedDate);

      let currentDate = new Date();
      currentDate.setMinutes(0);
      currentDate.setHours(0);
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);

      return currentDate <= selectedDate;
    }
    return true
  }, $.validator.messages.checkDate);

  $.validator.addMethod('cardNum', function(input, element) {
    return cc_mod10(input.replace(/\s/g, ''));
  }, $.validator.messages.cardNum);

  let validator = $('#cardForm').validate({
    rules: {
      address: {
        required: true,
      }, 
      card_num: { 
        required: true,
        minlength: 16,
        cardNum: true
      },
      card_exp_month: {
        required: true,
        checkDate: true,
        digits: true
      },
      card_exp_year: {
        required: true,
        checkDate: true,
        digits: true
      },
      cvv2: {
        maxlength: 3,
        required: true,
        digits: true,
        minlength: 3,
      },
    },
    groups: {
      date_group: 'card_exp_month card_exp_year',
    },
    // For custom messages
    errorElement: 'div',
    errorClass: "invalid form-error",
    errorPlacement: function(error, element) {
      error.addClass('error-message');
      error.insertAfter(element);
    },
    submitHandler: function(form) {
      $('#cardExpirationYear').attr('maxlength','4');
      if(validator.valid()){
        showLoader(); 
        setTimeout(function() { 
          $('#cardExpirationYear').val(
            '20' + $('#cardExpirationYear').val()
          ); 
          console.log($('#cardExpirationYear').val()); 
          $('#cardExpirationYear').rules("remove") 
          $('#cardExpirationMonth').rules("remove")
          $('#cardNumber').val(
            $('#cardNumber').val().replace(/\s/g, '')
          );
          $('#cardForm').addClass('submited');
          form.submit();
        });
      } else {
        return false;
      }
    }
  });

  if ($("#error").html() != '') {
    $(".error_wrapper").addClass('visible');
  } else {
    $(".error_wrapper").removeClass('visible');
  }

  $('.reload_btn').on('click', function() {
    location.reload();
  })

  $('select').change(function(){
    $('#cardForm').valid();
  })

  $(".cvv").on("click", function() {
    $(this).addClass('clicked');
  })
  $('.cvv').focusin(function() {
    $(this).addClass('clicked');
  });

  $("#info-icon").mouseover (function(){$(".tooltip").addClass("visible")});
  $("#info-icon").mouseout (function(){$(".tooltip").removeClass("visible")});

  $('#cardExpirationYear').on('input', function() {
    $(this).val($(this).val().replace(/[^0-9]/gi, ''));
  });

  $('#cvv-mask').on('input', function() {
    $(this).val($(this).val().replace(/[^0-9]/gi, ''));
  });

  $('#cardExpirationMonth').on('input', function() {
    if (!/^(0|1|1[0-2]|0[1-9])$/.test(this.value)) { this.value = this.value.substring(0, this.value.length-1) } 
  });

  $('#cardExpirationYear').on('focus', function () {
    if ($(this).hasClass('untouched')) {
      $(this).removeClass('untouched');
    }
  });

  $('.content').css('visibility', 'visible');
  $('.preloader').removeClass('visible');

}); 
